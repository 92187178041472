<template>
  <div>
    <div class="business_bg">
      <img src="../../static/image/business_bg.png" />
    </div>
    <div class="business_list" v-loading="loading">
      <div
        class="business_list_item moveTop"
        @click="toDetails(item)"
        v-for="item in businessList"
        :key="item.newsId"
      >
        <div class="business_list_item_img">
          <img
          v-if="item.newsCover"
          :src="item.newsCover"
          width="280px"  style="max-height: 280px;transform: scale(2);"
        />
        </div>
        <div class="business_list_item_text">{{ item.title }}</div>
        <div class="business_list_item_time">
          上市时间：{{ item.year }}.{{ item.day }}
        </div>
      </div>
    </div>
    <div class="business_pagination">
      <el-pagination
        layout="prev, pager, next"
        :page-size="8"
        :total="query.total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "business",
  data() {
    return {
      businessList: ["", "", "", "", "", "", "", ""],
      loading: false,
      query: {
        page: 1,
        size: 8,
        total: 0,
      },
    };
  },
  mounted() {
    window.scroll(0, 0);
    this.getList();
  },
  methods: {
    toDetails(item) {
      this.$router.push({ path: "/businessDetail", query: { id: item.newsId } });
    },
    getList() {
      this.loading = true;
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?status=PUBLISHED&current=${
            this.query.page
          }&size=8&websiteCategoryId=5`, // 16
        )
        .then(async (res) => {
          const content = res.data.data.list;
          content.forEach((item) => {
            const updateTime = new Date(item.publishTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.newsTitle.length > 30
                ? item.newsTitle.substring(0, 30) + "..."
                : item.newsTitle;
          });
          this.businessList = content;
          this.query.total = res.data.data.total;
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.business_bg {
  min-width: 1280px;
  img {
    width: 100%;
  }
}
.business_list {
  margin: 80px auto 20px auto;
  width: 1280px;
  .business_list_item {
    cursor: pointer;
    margin-right: 50px;
    margin-bottom: 40px;
    display: inline-block;
    .business_list_item_img{
      width: 280px;
      height: 280px;
      overflow: hidden;
    }
    .business_list_item_text {
      margin-top: 10px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 28px;
      max-width: 280px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .business_list_item_time {
      margin-top: 20px;
      width: 170px;
      height: 28px;
      background: #efefef;
      font-size: 14px;
      font-weight: 400;
      color: #abaeb0;
      line-height: 28px;
      text-align: center;
    }
  }
  .business_list_item:nth-child(4) {
    margin-right: 0;
  }
  .business_list_item:nth-child(8) {
    margin-right: 0;
  }
}
.business_pagination {
  min-width: 1280px;
  text-align: center;
  margin-bottom: 64px;
}
::v-deep .el-pager li.active {
  width: 28px;
  height: 28px;
  background: #9e1307;
  border-radius: 2px;
  color: #ffffff;
  line-height: 28px;
}
::v-deep .el-pager li {
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  color: #565759;
  line-height: 28px;
  padding: 0;
  margin: 0 4px;
  font-weight: 400;
}
::v-deep .el-pager li.active + li {
  border: 1px solid #e5e5e5;
}
</style>
